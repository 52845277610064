import { Countries } from '@xoda/common'
import pluralize from 'pluralize'
import { FC, Key, useState } from 'react'
import { useRecoilState } from 'recoil'
import { createBooleanDataset, toCapitalized } from '../../helpers/others'
import { ReactComponent as ArrowDown1 } from '../../icons/arrow-down-1.svg'
import { gymState } from '../../store/gym'
import { Button } from '../Button'
import s from './Goods.module.css'

const Goods: FC<GoodsProps> = (props) => {
  const {
    items,
    defaultSelected,
    large = false,
    groups = false,
    onSelect,
  } = props

  const [gym] = useRecoilState(gymState)

  const [selected, setSelected] = useState<Key | undefined>(defaultSelected)
  const [foldings, setFoldings] = useState<Record<string, boolean>>({})

  const createOnItemClick = (item: GoodsProps['items'][number]) => () => {
    setSelected(item.key)
    onSelect?.(item)
  }

  const createHandleFold = (key: string) => () => {
    setFoldings((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  const displayItems = groups
    ? items.reduce((pool, item) => {
        if (item.type in pool) {
          pool[item.type].push(item)
        } else {
          pool[item.type] = [item]
        }

        return pool
      }, {} as Record<GoodsProps['items'][number]['type'], GoodsProps['items']>)
    : items

  const renderItems = (items: GoodsProps['items']) => {
    return (
      <ul className={s.list} {...createBooleanDataset({ large })}>
        {items.map((item) => (
          <li
            key={item.key}
            className={s.item}
            {...createBooleanDataset({ selected: item.key === selected })}
            onClick={createOnItemClick(item)}
          >
            {large && <div className={s.type}>{toCapitalized(item.type)}</div>}
            <div className={s.info}>
              <div className={s.price}>
                <div className={s['price-number']}>
                  {Countries.formatAmount(item.price, gym?.currency_code, true)}
                </div>
                {item.per && <div className={s.per}>per {item.per}</div>}
              </div>
              <div>
                <div className={s.name}>
                  {item.name}{' '}
                  {item.type === 'pack' &&
                    `×${item.classesNumber} Class${
                      item.classesNumber > 1 ? 'es' : ''
                    }`}
                </div>
                {item.duration && item.durationUnit && (
                  <div className={s.duration}>
                    Valid for {item.duration} {item.durationUnit}
                    {item.duration > 1 ? 's' : ''}
                  </div>
                )}
                {item.type === 'plan' && item.trialPeriod && (
                  <div className={s.duration}>
                    {item.trialPeriod} day
                    {item.trialPeriod > 1 ? 's' : ''} for trial
                  </div>
                )}
              </div>
              {large && <div className={s.description}>{item.description}</div>}
            </div>
            {large && (
              <dl className={s.data}>
                {item.type === 'plan' && (
                  <>
                    <dt>Joining fee:</dt>
                    <dd>
                      {Countries.formatAmount(
                        item.joinFee,
                        gym?.currency_code,
                        true
                      )}
                    </dd>
                    <dt>Suspension fee:</dt>
                    <dd>
                      {Countries.formatAmount(
                        item.suspensionFee,
                        gym?.currency_code,
                        true
                      )}
                    </dd>
                  </>
                )}
                {item.type === 'pack' && (
                  <>
                    <dt>Classes and services:</dt>
                    <dd>{item.services.join(', ')}</dd>
                  </>
                )}
              </dl>
            )}
            <div className={s.select}>
              {item.key === selected ? (
                <Button variant="ghost" color="black">
                  selected
                </Button>
              ) : (
                <Button variant="ghost" color="white">
                  select
                </Button>
              )}
            </div>
          </li>
        ))}
      </ul>
    )
  }

  return Array.isArray(displayItems) ? (
    renderItems(displayItems)
  ) : (
    <>
      {Object.entries(displayItems).map(([type, groupItems]) => (
        <section
          key={type}
          className={s.group}
          {...createBooleanDataset({ folded: foldings[type] })}
        >
          <h1 className={s['group-title']} onClick={createHandleFold(type)}>
            {`${toCapitalized(type)}s`}
            <ArrowDown1 className={s.folder} />
          </h1>
          {renderItems(groupItems)}
        </section>
      ))}
    </>
  )
}

const mapPacks = (dtoPacks: Dto.Pack[]): GoodsProps['items'] =>
  dtoPacks.map((dtoPack) => ({
    key: dtoPack.id,
    type: 'pack',
    dto: dtoPack,
    price: dtoPack.price,
    name: dtoPack.name,
    description: dtoPack.description,
    classesNumber: dtoPack.pack_session_no,
    duration: dtoPack.pack_duration,
    durationUnit: dtoPack.pack_type,
    services: dtoPack.gym_pack_categories.map(
      (gymPackCategory) => gymPackCategory.gym_category.category.name
    ),
  }))

const mapProducts = (dtoProducts: Dto.Product[]): GoodsProps['items'] =>
  dtoProducts.map((dtoProduct) => ({
    key: dtoProduct.id,
    type: 'product',
    dto: dtoProduct,
    price: dtoProduct.price,
    name: dtoProduct.name,
    description: dtoProduct.description,
  }))

const mapPlans = (dtoPlans: Dto.Plan[]): GoodsProps['items'] =>
  dtoPlans.map((dtoPlan) => {
    if (dtoPlan.payment_schedule === 'full') {
      return {
        key: dtoPlan.id,
        type: 'plan',
        dto: dtoPlan,
        price: dtoPlan.full_price,
        joinFee: dtoPlan.join_fee || undefined,
        name: dtoPlan.name,
        description: dtoPlan.description,
        duration: dtoPlan.plan_duration,
        durationUnit: dtoPlan.plan_type.replace(/ly$/, ''),
        trialPeriod: dtoPlan.trial_days || undefined,
        suspensionFee: getSuspensionFee(dtoPlan),
      }
    }

    const price = getPlanPrice(dtoPlan)
    return {
      key: dtoPlan.id,
      type: 'plan',
      dto: dtoPlan,
      price: price.number,
      joinFee: dtoPlan.join_fee || undefined,
      name: dtoPlan.name,
      description: dtoPlan.description,
      per: price.per,
      trialPeriod: dtoPlan.trial_days || undefined,
      suspensionFee: getSuspensionFee(dtoPlan),
    }
  })

const getPlanPrice = (dtoPlan: Dto.Plan): { number: number; per: string } => {
  if (dtoPlan.version === 1) {
    const {
      member_fee: { amount: price },
      term: { type, length },
    } = dtoPlan.details!.payment

    return {
      number: price,
      per: length === 1 ? type : pluralize(type, Number(length), true),
    }
  }

  switch (dtoPlan.payment_schedule) {
    case null:
    case 'weekly':
      return { number: dtoPlan.price!, per: 'week' }

    case 'fortnightly':
      return { number: dtoPlan.fort_nightly_price, per: '2 weeks' }

    case 'monthly':
      return { number: dtoPlan.monthly_price, per: 'month' }

    default:
      throw new Error(
        `Unknown payment schedule \`${dtoPlan.payment_schedule}\``
      )
  }
}

const getSuspensionFee = (plan: Dto.Plan) => {
  const { version, details, payment_schedule } = plan

  if (!version) {
    switch (payment_schedule) {
      case 'weekly':
      case 'full':
        return plan.weekly_suspension_fee

      case 'fort_nightly':
        return plan.fort_nightly_suspension_fee

      case 'monthly':
        return plan.monthly_suspension_fee

      default:
        return 0
    }
  }

  return details?.payment.suspension_fee?.amount || 0
}

const isPlan = (item: unknown): item is Dto.Plan =>
  !!item && 'plan_type' in item

const isPack = (item: unknown): item is Dto.Pack =>
  !!item && 'pack_type' in item

const isProduct = (item: unknown): item is Dto.Product =>
  !!item && 'in_stock' in item

const getGoodsType = (item: GoodsItem['dto']) =>
  isPlan(item)
    ? 'plan'
    : isPack(item)
    ? 'pack'
    : isProduct(item)
    ? 'product'
    : undefined

type GoodsProps = {
  items: GoodsItem[]
  defaultSelected?: Key
  large?: boolean
  groups?: boolean
  onSelect?: (item: GoodsProps['items'][number]) => void
}

type GoodsItem = {
  key: Key
  price: number
  name: string
  description?: string
  per?: string
  duration?: number
  durationUnit?: string
} & (
  | {
      type: 'plan'
      dto: Dto.Plan
      joinFee: number | undefined
      suspensionFee: number
      trialPeriod: number | undefined
    }
  | {
      type: 'pack'
      dto: Dto.Pack
      classesNumber: number
      services: string[]
    }
  | {
      type: 'product'
      dto: Dto.Product
    }
)

export {
  Goods,
  mapPacks,
  mapProducts,
  mapPlans,
  isPlan,
  isPack,
  isProduct,
  getGoodsType,
}
export type { GoodsProps, GoodsItem }
